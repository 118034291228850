import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';

import { Layout, Link } from '$components';
import { getNavLinksFromEdges } from '../helpers/nextPreviousHelper';
import NextPrevious from '../components/NextPrevious';
import config from '../../config/config';
import { Edit, StyledHeading, StyledMainWrapper } from '../components/styles/Docs';

export default function DocsTemplate(props) {
  const { data, location } = props;

  if (!data) {
    return props.children;
  }
  const {
    allMdx,
    mdx,
    site: {
      siteMetadata: { docsLocation, title },
    },
  } = data;

  const githubIcon = require('../components/images/github.svg').default;

  // create NavigationLinks array
  const nav = getNavLinksFromEdges(allMdx.edges, location);

  // meta tags
  const metaTitle = mdx.fields.metaTitle;
  const metaDescription = mdx.fields.metaDescription;

  // canonical url
  let canonicalUrl = config.gatsby.siteUrl;
  canonicalUrl =
    config.gatsby.pathPrefix !== '/' ? canonicalUrl + config.gatsby.pathPrefix : canonicalUrl;
  canonicalUrl = canonicalUrl + mdx.fields.slug;

  return (
    <Layout {...props}>
      <Helmet>
        {metaTitle ? <title>{metaTitle}</title> : null}
        {metaTitle ? <meta name="title" content={metaTitle} /> : null}
        {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
        {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
        {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
        {metaDescription ? <meta property="twitter:description" content={metaDescription} /> : null}
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className={'titleWrapper'}>
        <StyledHeading>{mdx.fields.title}</StyledHeading>
        <Edit className={'mobileView'}>
          {docsLocation && (
            <Link className={'gitBtn'} to={`${docsLocation}/${mdx.parent.relativePath}`}>
              <img src={githubIcon} alt={'Github logo'} /> Edit on GitHub
            </Link>
          )}
        </Edit>
      </div>
      <StyledMainWrapper>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </StyledMainWrapper>
      <div className={'addPaddTopBottom'}>
        <NextPrevious mdx={mdx} nav={nav} edges={allMdx.edges} location={location} />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        slug
        title
        language
        path
        short
        category
        type
        template
        position
        teaser
        metaTitle
        metaDescription
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
            language
            path
            short
            category
            type
            template
            position
          }
        }
      }
    }
  }
`;
